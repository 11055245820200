import { PushNotifications } from "@capacitor/push-notifications";
import { IonToast, isPlatform } from "@ionic/react";
import { ChatContextEffects, ChatProvider } from "@src/app/chat/chatProvider";
import { SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION } from "@src/app/hcpShifts/constants";
import { useSelectedWorkType } from "@src/app/onboarding/hooks/useSelectedWorkType";
import { useShowSignNewAgreementPopup } from "@src/app/onboardingStripe/components/signNewAgreement/useShowSignNewAgreementPopup";
import { useAppSelector } from "@src/app/store";
import { useSession } from "@src/app/store/helperHooks";
import { setShowToast } from "@src/app/store/shift";
import { useZendeskMessaging } from "@src/app/zendesk/useZendeskMessaging";
import { AppV2PrivateRoutes } from "@src/appV2/App";
import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AssessmentStartTimePassedDialog } from "@src/appV2/SkillsAssessment/components/AssessmentStartTimePassedDialog";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import * as AppboyPlugin from "appboy-cordova-sdk/www/AppboyPlugin";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { isAgentInSignupProcess } from "./isAgentInSignupProcess";
import { OnBoardingRoutes } from "../../onboarding";
import { OnboardToStripe } from "../../onboardingStripe";
import { SignupBonusClaim } from "../../signupBonusClaim";
import { AppTabs } from "../../tabs";
import { GlobalRouterPath } from "../constant/globalRoute";
import { TabRouterPath } from "../constant/tabRoute";

export function HomeRoutes() {
  const session = useSession();
  const { userId, isSignup } = session;

  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });

  const { isPermanentPlacementsOnboarding } = useSelectedWorkType();

  const dispatch = useDispatch();

  useZendeskMessaging();
  useShowSignNewAgreementPopup();

  useEffect(() => {
    if (!isPlatform("capacitor") || !userId) {
      return;
    }
    // identifying the user on Braze
    AppboyPlugin.changeUser(userId);
    PushNotifications.requestPermissions().then((result) => {
      setTimeout(() => {
        if (result.receive === "granted") {
          PushNotifications.register();
        }
      }, 100);
    });
  }, [userId]);

  const showShiftCancelSuccessToast = useAppSelector(
    (store) => store.shiftStore.showToast === SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION.toastName
  );

  const { successToast: sentHomeSuccessToastOptions } = useCbhFlag(
    CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG,
    { defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG] }
  );

  if (!worker || !worker.userId || !userId) {
    if (isSignup) {
      return <OnBoardingRoutes agent={worker} />;
    } else {
      return <Redirect to={{ pathname: GlobalRouterPath.NO_USER_PAGE }} />;
    }
  }

  if (isAgentInSignupProcess(worker)) {
    return <OnBoardingRoutes agent={worker} />;
  }

  // TODO: I have a feeling that this is unnecessary and can be removed. If agent is still onboarding, condition from above will render onboarding routes, so what's the use case for this?
  // Maybe to protect users from just manually remove the html popup and proceed with using the app. This will, however, be irrelevant very soon since we'll allow users to close the popup
  if (
    !isPermanentPlacementsOnboarding &&
    worker.canBeOnboardedInStripe &&
    !worker.paymentAccountInfo
  ) {
    return (
      <OnboardToStripe
        nextStagePath={TabRouterPath.PAYMENT_SERVICE_ONBOARDING}
        disableBackButton={true}
      />
    );
  }
  if (
    !worker.hasClaimedBonus &&
    worker.applicantId &&
    worker.signupExperimentId &&
    worker.signupConfig &&
    worker.signupConfig.signingBonusAmount1 &&
    worker.signupConfig.signingBonusAmount2
  ) {
    return <SignupBonusClaim />;
  }

  return (
    <ChatProvider userId={userId} worker={worker}>
      <AssessmentStartTimePassedDialog />
      <ChatContextEffects>
        <Route path={GlobalRouterPath.HOME}>
          <AppTabs />
        </Route>
        <Route path={GlobalRouterPath.APP_V2_HOME}>
          <AppV2PrivateRoutes />
        </Route>
      </ChatContextEffects>
      <IonToast
        isOpen={showShiftCancelSuccessToast}
        header={
          sentHomeSuccessToastOptions?.header ?? SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION.header
        }
        onDidDismiss={() => dispatch(setShowToast())}
        message={
          sentHomeSuccessToastOptions?.message ?? SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION.message
        }
        duration={SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION.duration}
        color="success"
        position="top"
        data-testid="fcf-success-message"
      />
    </ChatProvider>
  );
}
